import FingerprintJS from '@fingerprintjs/fingerprintjs';

export default class ClientFingerprintService {
	public static async getFingerprintId(): Promise<string> {
		const agent = await FingerprintJS.load({
			monitoring: false,
		});
		const agentResult = await agent.get();
		const visitorId = FingerprintJS.hashComponents(agentResult.components);
		return visitorId;
	}
}
