'use client';

import { initializeApp, FirebaseApp, FirebaseOptions, getApps } from 'firebase/app';
import IUploadService from './IUploadService';
import FetchUtils, { AbortableRequest } from '../Utils/FetchUtils';

const FIREBASE_CONFIG: FirebaseOptions = {
	apiKey: 'AIzaSyBm2IDMzMkp2mjs4_ygVnNhmwR9eICH1uM',
	authDomain: 'pamela-douglas.firebaseapp.com',
	projectId: 'pamela-douglas',
	storageBucket: 'pamela-douglas.appspot.com',
	messagingSenderId: '580314987598',
	appId: '1:580314987598:web:bf12e88d72c91b9fe9d71f',
	measurementId: 'G-VQDVBH8KDD',
};

export default class FirebaseService implements IUploadService {
	private _app: FirebaseApp;

	constructor() {
		if (getApps().length === 0) {
			this._app = initializeApp(FIREBASE_CONFIG);
		} else {
			this._app = getApps()[0];
		}
	}

	public getPresignedUrlForUpload(fileName: string, file: File): AbortableRequest<string> {
		const request = FetchUtils.getJson<{ url: string }>(
			`/api/storage/upload?filename=${encodeURIComponent(fileName)}&type=${encodeURIComponent(file.type)}`,
		);

		return {
			abort: request.abort,
			response: new Promise((resolve, reject) => {
				request.response
					.then((response) => {
						resolve(response.url);
					})
					.catch(reject);
			}),
		};
	}

	public getPresignedUrlForDownload(fileName: string): AbortableRequest<string> {
		const request = FetchUtils.getJson<{ url: string }>(
			`/api/storage/download?filename=${encodeURIComponent(fileName)}`,
		);
		return {
			abort: request.abort,
			response: new Promise((resolve, reject) => {
				request.response
					.then((response) => {
						resolve(response.url);
					})
					.catch(reject);
			}),
		};
	}

	public getRedirectUrl(fileName: string) {
		return `/api/storage/redirect?filename=${encodeURIComponent(fileName)}`;
	}

	public uploadFile(fileName: string, file: File): AbortableRequest<string> {
		const urlRequest = this.getPresignedUrlForUpload(fileName, file);

		const aborts = [urlRequest.abort];

		return {
			abort: () => {
				aborts.forEach((abort) => abort());
			},
			response: new Promise((resolve, reject) => {
				urlRequest.response
					.then((url) => {
						const uploadRequest = FetchUtils.execute(url, {
							method: 'PUT',
							body: new Blob([file], { type: file.type }),
							headers: {
								'Content-Type': file.type,
							},
						});

						aborts.push(uploadRequest.abort);

						uploadRequest.response
							.then(() => {
								resolve(this.getRedirectUrl(fileName));
							})
							.catch(reject);
					})
					.catch(reject);
			}),
		};
	}
}
