'use client';

import React, { ReactNode, createContext, useContext } from 'react';
import FirebaseService from '../../Services/FirebaseService';

export const firebaseService = new FirebaseService();
export const FirebaseContext = createContext<FirebaseService>(firebaseService);

interface FirebaseProviderProps {
	children: ReactNode;
}

export default function FirebaseProvider(props: FirebaseProviderProps) {
	const { children } = props;

	return <FirebaseContext.Provider value={firebaseService}>{children}</FirebaseContext.Provider>;
}

export function useFirebaseService() {
	const context = useContext(FirebaseContext);
	return context;
}
